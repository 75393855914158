export default [
    {
        path: 'portal/professor',
        name: 'ProfessorHome',
        meta: {title: "Início"},
        component: () => import('@pages/ProfessorPages/Home.vue')
    },
    {
        path: 'portal/professor/cadastro',
        name: 'ProfessorInfo',
        meta: {title: "Cadastro"},
        component: () => import('@pages/ProfessorPages/Info.vue')
    },
    {
        path: 'portal/professor/turmas',
        name: 'ProfessorTurmas',
        meta: {title: "Turmas"},
        component: () => import('@pages/ProfessorPages/ProfessorTurmas.vue')
    },
    {
        path: 'portal/professor/cursostecnico',
        name: 'ProfessorCursosTecnico',
        meta: {title: "Cursos Técnicos"},
        component: () => import('@pages/ProfessorPages/ProfessorCursosTecnico.vue')
    },
    {
        path: 'portal/professor/boletimtecnico/:id',
        props: true,
        name: 'ProfessorBoletimTecnico',
        meta: {title: "Boletim Técnicos"},
        component: () => import('@pages/ProfessorPages/BoletimTecnico.vue')
    },
    {
        path: 'portal/professor/registrotecnico/:disciplina_turma_id',
        props: true,
        name: 'RegistroTecnico',
        meta: {title: "Registros Técnicos"},
        component: () => import('@pages/ProfessorPages/RegistroTecnico.vue')
    },
    {
        path: 'portal/professor/registrotecnico/create/:disciplina_turma_id/:hora/:data_aula/:duracao',
        props: true,
        name: 'RegistroTecnicoCreate',
        meta: {title: "Criar Registro"},
        component: () => import('@pages/ProfessorPages/RegistroTecnicoCreate.vue')
    },
    {
        path: 'portal/professor/registrotecnico/edit/:id',
        props: true,
        name: 'RegistroTecnicoEdit',
        meta: {title: "Editar Registro"},
        component: () => import('@pages/ProfessorPages/RegistroTecnicoEdit.vue')
    },
    {
        path: 'portal/professor/turmas/boletim/:id',
        props: true,
        name: 'BoletimTurma',
        meta: {title: "Turmas"},
        component: () => import('@pages/ProfessorPages/BoletimTurma.vue')
    },
    {
        path: 'portal/professor/calendario',
        name: 'ProfessorCalendario',
        meta: {title: "Calendário"},
        component: () => import('@pages/ProfessorPages/Calendario.vue')
    },
    {
        path: 'portal/professor/comunicado',
        name: 'ProfessorComunicado',
        meta: {title: "Comunicados"},
        component: () => import('@pages/ProfessorPages/Comunicado.vue')
    },
    {
        path: 'portal/professor/horario',
        name: 'ProfessorHorario',
        meta: {title: "Horários"},
        component: () => import('@pages/ProfessorPages/Horario.vue')
    },
    {
        path: 'portal/professor/diario',
        name: 'ProfessorDiario',
        meta: {title: "Diário de Classe"},
        component: () => import('@pages/ProfessorPages/ProfessorDiario.vue')
    },
    {
        path: 'portal/professor/diario/turma/:id',
        props: true,
        name: 'RegistroTurma',
        meta: {title: "Diário de Classe"},
        component: () => import('@pages/ProfessorPages/RegistroTurma.vue')
    },
    {
        path: 'portal/professor/diario/registro/create/:horarioProfessorId',
        props: true,
        name: 'RegistroCreate',
        meta: {title: "Criar Registro"},
        component: () => import('@pages/ProfessorPages/RegistroCreate.vue')
    },
    {
        path: 'portal/professor/diario/registro/edit/:id',
        props: true,
        name: 'RegistroEdit',
        meta: {title: "Editar Registro"},
        component: () => import('@pages/ProfessorPages/RegistroEdit.vue')
    },
    {
        path: 'portal/professor/diario/registro/justificar/:registro_aula_id',
        props: true,
        name: 'JustificarRegistroEdit',
        meta: {title: "Justificar Frequências"},
        component: () => import('@pages/ProfessorPages/JustificarFrequencia.vue')
    },
    {
        path: 'portal/professor/diario/registro/show/:id',
        props: true,
        name: 'RegistroShow',
        meta: {title: "Visualizar Registro"},
        component: () => import('@pages/ProfessorPages/RegistroShow.vue')
    },
]
