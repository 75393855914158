export default [

    {
        path: '/',
        name: 'AcademicoTecnicoHome',
        meta: {title: "Início"},
        component: () => import('@pages/AcademicoTecnico/Home.vue')
    },
    {
        path: 'tecnico/boletim',
        name: 'BoletimListTecnico',
        meta: {title: "Boletim"},
        component: () => import('@pages/AcademicoTecnico/BoletimList.vue')
    },
    {
        path: 'tecnico/rematricula',
        name: 'RematriculaTecnicoList',
        meta: {title: "Rematrícula"},
        component: () => import('@pages/AcademicoTecnico/RematriculaList.vue')
    },
    {
        path: 'tecnico/periodorematricula/:periodo_tecnico_id/:prox_periodo_id',
        props: true,
        name: 'PeriodoTecnicoRematricula',
        meta: {title: "Rematrícula - Período"},
        component: () => import('@pages/AcademicoTecnico/PeriodoTecnicoRematricula.vue')
    },
    {
        path: '/boletim/show/:id',
        props: true,
        name: 'BoletimShowTecnico',
        meta: {title: "Visualizar Boletim"},
        component: () => import('@pages/AcademicoTecnico/BoletimShow.vue')
    },
]
