export default [
    {
        path: '',
        name: 'HomeSite',
        meta: {title: "Início"},
        component: () => import('@pages/Site/Home.vue')
    },
    {
        path: '/sobre-site',
        name: 'SobreSite',
        meta: {title: "EEMAN - Sobre"},
        component: () => import('@pages/Site/Sobre.vue')
    },
    {
        path: '/noticia-site',
        name: 'NoticiaSite',
        meta: {title: "EEMAN - Notícias"},
        component: () => import('@pages/Site/Noticia.vue')
    },
    {
        path: '/politica-privacidade',
        name: 'PoliticaPrivacidade',
        meta: {title: "EEMAN - Política de privacidade"},
        component: () => import('@pages/Site/PoliticaPrivacidade.vue')
    },
    {
        path: '/termos-uso',
        name: 'TermosUso',
        meta: {title: "EEMAN - Termos de uso"},
        component: () => import('@pages/Site/TermosUso.vue')
    },
    {
        path: '/noticia-site/noticia-page/:id',
        props: true,
        name: 'NoticiaPageSite',
        meta: {title: "EEMAN - Notícias"},
        component: () => import('@pages/Site/NoticiaPage.vue')
    },
    {
        path: '/cursos-eeman',
        name: 'CursoEeman',
        meta: {title: "EEMAN - Cursos"},
        component: () => import('@pages/Site/Curso.vue')
    },
    {
        path: '/editais',
        name: 'EditaisSite',
        meta: {title: "EEMAN - Editais"},
        component: () => import('@pages/Site/Editais.vue')
    },
]
